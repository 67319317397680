<template>
  <b-container fluid class="component-reports-balance-asset-growth-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-balance-asset-growth-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'AssetGrowthBarChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    chartKpi: { type: String, default: 'closing_balance' },
    chartLevel: { type: String, default: 'account_group' }, // [total, account_group, account]
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.asset_growth.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []

      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      const suffix = ' ' + this.currencySymbol
      const _self = this
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return _self.$t(_self.translationPath + 'kpi.' + _self.chartKpi) + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const datasets = []
      let palette = this.getChartPalette()

      // LEVEL: TOTAL
      if (this.chartLevel === 'total') {
        const dataset = { backgroundColor: palette.shift(), type: 'bar', label: this.$t('common.total'), data: [] }

        for (const period in this.apiData.totals.periods_budget) {
          dataset.data.push(this.apiData.totals.periods_budget[period][this.chartKpi])
        }
        datasets.push(dataset)
      }

      // LEVEL: ACCOUNT GROUP
      if (this.chartLevel === 'account_group') {
        this.chartOptions.scales.yAxes[0].stacked = true
        this.chartOptions.scales.xAxes[0].stacked = true

        for (const iGroupIdx in this.apiData.account_groups) {
          let iSum = 0
          const oGroup = this.apiData.account_groups[iGroupIdx]
          const dataset = { backgroundColor: null, type: 'bar', label: oGroup.title, data: [] }

          for (const period in oGroup.periods_budget) {
            iSum += parseInt(oGroup.periods_budget[period][this.chartKpi])
            dataset.data.push(oGroup.periods_budget[period][this.chartKpi])
          }
          if (iSum > 0) {
            dataset.backgroundColor = palette.shift()
            datasets.push(dataset)
          }
        }
      }

      // LEVEL: ACCOUNT
      if (this.chartLevel === 'account') {
        palette = this.getMultiHuePalette()
        this.chartOptions.scales.yAxes[0].stacked = true
        this.chartOptions.scales.xAxes[0].stacked = true
        // this.chartOptions.legend = true

        for (const iGroupIdx in this.apiData.account_groups) {
          for (const iAccountIdx in this.apiData.account_groups[iGroupIdx].accounts) {
            const oAccount = this.apiData.account_groups[iGroupIdx].accounts[iAccountIdx]
            let iSum = 0
            const dataset = { backgroundColor: null, type: 'bar', label: oAccount.title, data: [] }

            for (const period in oAccount.periods_budget) {
              iSum += parseInt(oAccount.periods_budget[period][this.chartKpi])
              dataset.data.push(oAccount.periods_budget[period][this.chartKpi])
            }

            if (iSum > 0) {
              dataset.backgroundColor = palette.shift()
              datasets.push(dataset)
            }
          }
        }
      }

      this.chartData = {
        labels: labels,
        datasets: datasets
      }
    }
  },
  watch: {
    apiData () { this.fillChart() },
    chartLevel () { this.fillChart() },
    chartType () { this.fillChart() },
    chartKpi () { this.fillChart() }
  }
}
</script>

<template>
  <div class="component-risk-scale-total-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="6">
                  <h2>{{ $t(translationPath + 'title_' ,{start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="6" class="text-right">

                  <!-- Toggle KPI -->
                  <b-dropdown
                    :text="$t('common.charts.toggle_kpi') + ' | ' + $t( translationPath + 'kpi.' + chartKPI)"
                    variant='outline-secondary'
                    class="float-right mr-3 toggleColumnWrapper"
                    size="sm"
                  >

                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'opening_balance')" v-bind:class="{ active: (chartKPI === 'opening_balance') }">{{$t(translationPath + 'kpi.opening_balance')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'deposit')" v-bind:class="{ active: (chartKPI === 'deposit') }">{{$t(translationPath + 'kpi.deposit')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'change_in_value')" v-bind:class="{ active: (chartKPI === 'change_in_value') }">{{$t(translationPath + 'kpi.change_in_value')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'value_before_tax')" v-bind:class="{ active: (chartKPI === 'value_before_tax') }">{{$t(translationPath + 'kpi.value_before_tax')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'tax')" v-bind:class="{ active: (chartKPI === 'tax') }">{{$t(translationPath + 'kpi.tax')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'withdrawal')" v-bind:class="{ active: (chartKPI === 'withdrawal') }">{{$t(translationPath + 'kpi.withdrawal')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, chartLevel, 'closing_balance')" v-bind:class="{ active: (chartKPI === 'closing_balance') }">{{$t(translationPath + 'kpi.closing_balance')}}</b-dropdown-item>
                  </b-dropdown>

                  <!-- Toggle level -->
                  <b-dropdown right
                              :text="$t('common.charts.toggle_level') + ' | ' + $t( 'common.charts.levels.'+chartLevel)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart(chartType, 'total', chartKPI)" v-bind:class="{ active: (chartLevel === 'total') }">{{$t('common.charts.levels.total')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, 'account_group', chartKPI)" v-bind:class="{ active: (chartLevel === 'account_group') }">{{$t('common.charts.levels.account_group_asset')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart(chartType, 'account', chartKPI)" v-bind:class="{ active: (chartLevel === 'account') }">{{$t('common.charts.levels.account_asset')}}</b-dropdown-item>
                  </b-dropdown>

                  <!-- Toggle chart type -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( 'common.charts.types.'+chartType)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('bar_chart', chartLevel, chartKPI)" v-bind:class="{ active: (chartType === 'bar_chart') }">{{$t('common.charts.types.bar_chart')}}</b-dropdown-item>
                  </b-dropdown>

                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_total_table')"></div>
            </div>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import Loader from '@/components/common/Loader'
// import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'AssetGrowthTable',
  // components: { Loader },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.asset_growth.',
      tableData: [],
      tableColumns: [],
      chartType: 'bar_chart',
      chartLevel: 'total',
      chartKPI: 'closing_balance'
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (chartType, chartLevel, chartKPI) {
      this.chartType = chartType
      this.chartLevel = chartLevel
      this.chartKPI = chartKPI
      this.$emit('chart-to-display', { chartType: chartType, chartLevel: chartLevel, chartKPI: chartKPI })
    }
  },
  watch: {
    apiData: {
      handler: function () {
        // this.tableColumns = this.buildTableColumns()
        // this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-productive-assets-total-table{
}
</style>

<template>
  <div class="component-reports-balance-asset-growth">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                default-period-type="rolling-3-months"
                :show-resolution="true"
                :show-predefined-periods="false"
                :show-predefined-long-term-periods="true"
                :default-resolution="resolution"
                :show-period-option-full-period="true"
                :allowed-resolutions="['month', 'year']"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <report-submenu :active="activeTab" @tab-switched="onTabSwitched" />
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">
        <div class="pb-5" style="width:100%;height:500px;">

          <asset-growth-bar-chart v-if="activeTab === 'summary' && chartType === 'bar_chart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :chartLevel="chartLevel"
            :chartKpi="chartKPI"
            :isLoaded="!busyState"
            :resolution="resolution"
          />
        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->

      <asset-growth-table v-if="activeTab === 'summary'"
         :is-loaded="!busyState"
         :api-data="apiData"
         :start-date="startDate"
         :end-date="endDate"
         :resolution="resolution"
         @chart-to-display="receiveChartParams"
      />

      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="['income', 'expense', 'liability']"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import axios from 'axios'
import ReportSubmenu from './inc/AssetGrowthSubmenu'
import ReportFilter from '@/components/common/ReportFilter.vue'
import AssetGrowthBarChart from './charts/AssetGrowthBarChart'
import AssetGrowthTable from './views/AssetGrowthTable'
import SpirectaReportMixin from '@/views/reports/_inc/SpirectaReportMixin'

export default {
  name: 'AssetGrowth',
  mixins: [titleMixins, SpirectaReportMixin],
  components: {
    ReportSubmenu,
    PeriodSelector,
    ReportFilter,
    AssetGrowthBarChart,
    AssetGrowthTable
  },
  data () {
    return {
      renderKey: 0,
      validRouteParams: ['summary', 'filter'],
      activeTab: 'summary',
      apiData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.balance.asset_growth.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'year',
      chartType: 'bar_chart',
      chartLevel: 'total',
      chartKPI: 'closing_balance',
      reportFilterData: null,
      bIsFilterApplied: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      let aBreadcrumb = []

      aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/balance' },
        { text: this.$t('reports._common.balance_reports'), to: '/reports/balance/' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    }
  },
  methods: {
    receiveChartParams (data) {
      this.chartType = data.chartType
      this.chartLevel = data.chartLevel
      this.chartKPI = data.chartKPI
      this.renderKey++
    },
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/balance/asset-growth?start_date=${this.startDate}&end_date=${this.endDate}&resolution=${this.resolution}`

        // Filter accounts
        const aIncludeAccounts = []
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectAsset')) {
          this.reportFilterData.oFormSelectAsset.map(a => { aIncludeAccounts.push(a) })
        }
        if (aIncludeAccounts.length) {
          sApiEndpoint += '&filter_accounts_assets=' + aIncludeAccounts.join(',')
        }

        // Filter persons
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectPerson')) {
          sApiEndpoint += '&filter_persons=' + this.reportFilterData.oFormSelectPerson.join(',')
        }

        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    }
  },
  mounted () {
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-reports-balance-asset-growth {
  width: 100%;
}
</style>
